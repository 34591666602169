import React from "react"
import { FormattedMessage } from "react-intl"
import { config } from "../../lib/config"

export const BestCareerFooter: React.FC = () => (
  <footer className="text-center py-3 footer">
    <div className="container">
      <div className="row">
        <div className="col-md-6 text-md-left">
          <FormattedMessage
            id="bestcareer.footer.copyright"
            defaultMessage="Copyright © {year} career Institut &amp; Verlag, a company of the GPK group, Gußhausstraße 14/2, 1040 Wien, +43 1 585 69 69"
            values={{
              year: config.package.buildYear,
            }}
          />{" "}
          <a href="mailto:gpk@gpk.at">gpk@gpk.at</a>
        </div>
        <div className="col-md-6 text-md-right">
          <a
            className="px-1"
            href="https://career.gmbh/impressum/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              id="bestcareer.footer.imprint"
              defaultMessage="Imprint"
            />
          </a>
          <a
            className="px-1"
            href="https://gpk.at/agb/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              id="bestcareer.footer.terms"
              defaultMessage="Terms and conditions"
            />
          </a>
          <a
            className="px-1"
            href="https://gpk.at/datenschutz/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FormattedMessage
              id="bestcareer.footer.dataProtection"
              defaultMessage="Data protection"
            />
          </a>
        </div>
      </div>
    </div>
  </footer>
)

export default BestCareerFooter
